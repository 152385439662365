import React, { useEffect } from "react";
import { connect } from "react-redux";
// import Card from "../features/accordion/card";
import { Link } from "gatsby";
import slugify from "slugify";
import { GatsbyImage } from "gatsby-plugin-image";
// import Accordion from "../features/accordion/accordion";

import {
  getCartItems,
  getDiscount,
  removeFromCart,
  getOrder,
} from "../../reducers/cart";

function OrderSummary({
  cartlist,
  metadata,
  discount,
  removeFromCart,
  shipping,
  progress,
  type,
  order,
}) {
  const { taxRate } = metadata;

  const [totalSave = 0, setTotalSave] = React.useState(0);
  useEffect(() => {
    const save = cartlist.reduce(
      (acc, { price, oldPrice, quantity }) =>
        acc +
        (Number(oldPrice) - Number(price) > 0
          ? Number(oldPrice) - Number(price) * quantity
          : 0),
      0
    );
    setTotalSave(save + Number(discount.amount));
  }, [cartlist, discount]);
  const subtotal = cartlist.reduce(
    (acc, { price, quantity }) => acc + price * quantity,
    0
  );

  let shippingAmount = 0;
  if (progress !== "info") {
    shippingAmount = Number(shipping);
  }

  const shouldChargeTax = () => {
    return (
      order.customer.state.toLowerCase() ===
      metadata.shippingOrigin.state.toLowerCase()
    );
  };
  let tax = 0;
  if (progress !== "info") {
    if (type === "shipping" && !shouldChargeTax()) {
      tax = 0;
    } else {
      tax =
        ((subtotal - discount.amount + shippingAmount ) * taxRate) /
        100;
    }
  }

  const total = subtotal + tax + shippingAmount  - discount.amount;

  return (
    <div className="summary">
      <h3 className="summary-title">Your Order</h3>

      <table className="table table-summary ">
        <thead>
          <tr>
            <th>Preview</th>
            <th>Product</th>
            <th>Total</th>
          </tr>
        </thead>

        <tbody>
          {cartlist.map((item, index) => (
            <tr key={index} className="pb-2">
              <td>
                <GatsbyImage
                  image={item.thumbnail.childImageSharp?.gatsbyImageData}
                  className="m-2"
                />
                <span className="img-pill">{item.quantity}</span>
              </td>
              <td>
                <Link
                  className="p-3"
                  to={`/products/${
                    item.seoMetaData?.slug || slugify(item.title.toLowerCase())
                  }/`}
                >
                  {item.title}
                </Link>
                {progress === "info" && (
                  <button
                    onClick={(e) => removeFromCart(item._id)}
                    class="btn-remove"
                    title="Remove Product"
                  >
                    <i aria-label="sad" class="icon-close"></i>
                  </button>
                )}
              </td>

              <td>
                {" "}
                $
                {Number.parseFloat(item.quantity * item.price)
                  .toFixed(2)
                  .toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </td>
            </tr>
          ))}
          <tr className="summary-subtotal">
            <td>Subtotal:</td>
            <td></td>
            <td>
              {" "}
              ${" "}
              {cartlist.length > 0
                ? Number.parseFloat(
                    cartlist.reduce(
                      (acc, { quantity, price }) => acc + quantity * price,
                      0
                    )
                  ).toFixed(2)
                : "0"}
            </td>
          </tr>
          {discount.amount !== 0 && (
            <tr>
              {" "}
              <td>Discount:</td>
              <td></td>
              <td>- $ {discount.amount.toFixed(2)}</td>
            </tr>
          )}{" "}
          <tr>
            <td>Tax:</td>
            <td></td>

            <td>$ {tax.toFixed(2)}</td>
          </tr>
          {progress !== "info" && (
            <tr >
              <td>Shipping:</td>
              <td></td>
              <td> $ {shippingAmount}</td>
            </tr>
          )}
          <tr className="summary-total">
            <td>Total:</td>
            <td></td>
            <td>${total.toFixed(2)}</td>
            {/* <td>${ ( total + shippingPrice[ props.shipping ] ).toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }</td> */}
          </tr>
          {totalSave > 0 && (
            <tr>
              <td colSpan={4} className=" bg-success text-white p-1">
                <span className="d-flex justify-content-end">
                  {/* <LazyLoadImage
                src={"/assets/images/money_bag.svg"}
                width={40}
                height={40}
              />{" "} */}
                  <b className="ml-2">You saved: $ {totalSave.toFixed(2)}</b>
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* <Accordion type="checkout">
        <Card title="Direct bank transfer" expanded={true}>
          Make your payment directly into our bank account. Please use your
          Order ID as the payment reference. Your order will not be shipped
          until the funds have cleared in our account.
        </Card>

        <Card title="Check payments">
          Ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.
          Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
        </Card>

        <Card title="Cash on delivery">
          Quisque volutpat mattis eros. Lorem ipsum dolor sit amet, consectetuer
          adipiscing elit. Donec odio. Quisque volutpat mattis eros.
        </Card>

        <Card title="PayPal">
          <small className="float-right paypal-link">What is PayPal?</small>
          Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non,
          semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis
          fermentum.
        </Card>

        <Card title="Credit Card (Stripe)">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/payments-summary.png`}
            alt="payments cards"
          />
          Donec nec justo eget felis facilisis fermentum.Lorem ipsum dolor sit
          amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat
          mattis eros. Lorem ipsum dolor sit ame.
        </Card>
      </Accordion> */}

      {/* <button  */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  cartlist: getCartItems(state),
  discount: getDiscount(state),
  order: getOrder(state),
});

export default connect(mapStateToProps, { removeFromCart })(OrderSummary);
