import React,{useEffect} from "react";
import { connect } from "react-redux";
import { getCartItems, getDiscount, getOrder } from "../../reducers/cart";


function OrderPlaced( {orderNumber,
  isPickup,
  email,
  cartlist,
  discount,
  metadata,
  type,
  distanceBasedShippingFee,
}) {
  useEffect(() => {
    pushDataLayer();
  });
  const pushDataLayer = () => {
    const {
      taxRate,
      shippingPrice: sp,
      freeShippingCap: fsc,
      isFlatFeeEnabled,
    } = metadata;
    if (window && typeof window != "undefined") {
      console.log("Pushed to Analytics");
      const subtotal = cartlist.reduce(
        (acc, { price, quantity }) => acc + price * quantity,
        0
      );

      let shipping = 0;
      if (!isPickup) {
        if (isFlatFeeEnabled) {
          if (sp === 0 && fsc === 0) shipping = 0;
          else if ((fsc === 0 && sp > 0) || (fsc > 0 && fsc > subtotal))
            shipping = sp;
        } else if (type === "frigidaire") {
          if (cartlist.cart.length === 1) {
            if (cartlist.cart[0].quantity > 1) {
              shipping = cartlist.cart[0].quantity * 30;
            } else {
              shipping = 125;
            }
          } else {
            shipping = cartlist.cart.reduce(
              (acc, { quantity }) => acc + 30 * quantity,
              0
            );
          }
        } else {
          shipping = Number(distanceBasedShippingFee)
            ? Number(distanceBasedShippingFee)
            : 0;
        }
      }

      let extraFee = cartlist.reduce(
        (acc, { quantity, isCrate }) => acc + (isCrate ? 35 * quantity : 0),
        0
      );
      const tax =
        ((subtotal - discount.amount + shipping + extraFee) * taxRate) / 100;
      const total = subtotal + tax + shipping + extraFee - discount.amount;
      const items = cartlist.map((item) => {
        return {
          item_name: item.title,

          currency: "USD",
          price: item.price,
          quantity: item.quantity,
        };
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "purchase",
        data: {
          currency: "USD",
          transaction_id: orderNumber,
          subtotal: subtotal,
          total: total,
          isPickup: isPickup,
          shipping: shipping,
          extraFee: extraFee,
          tax: tax,
          discount: discount,
          items,
        },
      });
    }
  };
    return (
    <div className={`icon-box-sm text-center`}>
       <span className={`icon-box-icon`}>
        <i className={"icon-check"}></i>
      </span>

      <div className="icon-box-content">
        <h3 className="icon-box-title">Thank You for your purchase</h3>
        {orderNumber !== null && <p>Order Number: #{orderNumber}</p>}
        {/* <p dangerouslySetInnerHTML={ safeContent( text ) }></p> */}
        <p className="mb-4">
      Order Placed Successfully. You'll be notified via email about the order
      status.
    </p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  order: getOrder(state),
  cartlist: getCartItems(state),
  discount: getDiscount(state),
});
export default connect(mapStateToProps, null)(OrderPlaced);
